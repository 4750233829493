﻿import './assetInputField.js'

// Make sure all links pointing to assets (starting with /library/) are opened in a new window to prevent Blazor routing from interfering with them
window.addEventListener("message", (event) => {
	if (event.data === 'veva-page-loaded') {
		for (const link of document.querySelectorAll('a')) {
			const href = link.getAttribute('href') || '';
			if (href.startsWith('/library/')) {
				const target = link.getAttribute('target') || '';
				if (target === '') {
					link.setAttribute('target', '_blank');
				}
			}			
		}
	}
});

window.vevaAutoPlayVideo = function (videoId) {
	let video = document.getElementById(videoId);
	if (video) {
		video.muted = true;
		video.play();
	}
}